import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Alert, Button } from 'reactstrap';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import formatPrice from 'utils/formatPrice';
import { actions as reportsActions } from 'models/reports/slice';
import { actions as commonActions } from 'models/common/slice';
import {
  salesCountSelector,
  salesRevenueSelector,
  averagePriceSelector,
  footTrafficSelector,
  salesTastingRoomTotalSelector,
  salesSubscriptionsTotalSelector,
  salesCommercialTotalSelector,
  salesContainerWorldTotalSelector,
  salesEcommerceTotalSelector,
  salesRetailTotalSelector,
  salesPendingSelector,
  salesRefundsTotalSelector,
  salesAllSelector,
  paginationSelector,
  reportsOrdersSelector,
} from 'models/reports/selectors';
import {
  isOpenEmailModalSelector,
  isOpenSuccessModalSelector,
} from 'models/common/selectors';
import { isPendingSelector as brandsIsPendingSelector } from 'models/brands/selectors';
import SalesStatCard from 'components/SalesStatCard';
import SalesSourcesCard from 'components/SalesSourcesCard';
import SalesTipsCard from 'components/SalesTipsCard';
import SalesOverviewCard from 'components/SalesOverviewCard';
import ReportsLayout from 'components/ReportsLayout';
import Orders from './Orders';
import useQuery from 'hooks/useQuery';
import { getEndDate, getMockDate } from 'utils/date';
import { format } from 'date-fns';
import ReportFilters from 'components/ReportFilters';
import EmailPopup from 'components/Popup/EmailPopup/EmailPopup';
import PopupBase from 'components/Popup/PopupBase';

import styles from './ReportsSalesSummary.scss';

const ReportsSalesSummary = ({ title }) => {
  // Query
  const query = useQuery();

  // States
  const [dateRange, setDateRange] = useState({
    startDate:
      query.get('date_range_start') ||
      format(getMockDate(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    endDate:
      query.get('date_range_end') ||
      format(getEndDate(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
  });

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedOrderTypes, setSelectedOrderTypes] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(pagination?.current_page);
  const [isLongDateRange, setIsLongDateRange] = useState(false);

  // Selectors
  const pending = useSelector(salesPendingSelector);
  const salesRevenue = useSelector(salesRevenueSelector);
  const salesAll = useSelector(salesAllSelector);
  const salesCount = useSelector(salesCountSelector);
  const averagePrice = useSelector(averagePriceSelector);
  const footTraffic = useSelector(footTrafficSelector);
  const tastingRoomTotal = useSelector(salesTastingRoomTotalSelector);
  const subscriptionsTotal = useSelector(salesSubscriptionsTotalSelector);
  const commercialTotal = useSelector(salesCommercialTotalSelector);
  const containerWorldTotal = useSelector(salesContainerWorldTotalSelector);
  const ecommerceTotal = useSelector(salesEcommerceTotalSelector);
  const retailTotal = useSelector(salesRetailTotalSelector);
  const refundsTotal = useSelector(salesRefundsTotalSelector);
  const brandsPending = useSelector(brandsIsPendingSelector);
  const orders = useSelector(reportsOrdersSelector);
  const pagination = useSelector(paginationSelector);
  const isOpenEmailModal = useSelector(isOpenEmailModalSelector);
  const isOpenSuccessModal = useSelector(isOpenSuccessModalSelector);

  // Actions
  const fetchSalesRequest = useAction(reportsActions.fetchSales);
  const fetchOrders = useAction(reportsActions.fetchOrders);
  const fetchSalesXlsxRequest = useAction(reportsActions.fetchSalesXlsx);
  const setIsOpenEmailModal = useAction(commonActions.setIsOpenEmailModal);
  const setIsOpenSuccessModal = useAction(commonActions.setIsOpenSuccessModal);

  // Effects
  useEffect(() => {
    // Check if date range exceeds 1 month
    const checkLongDateRange = () => {
      const start = new Date(dateRange?.startDate);
      const end = new Date(dateRange?.endDate);

      return (
        start.getMonth() !== end.getMonth() ||
        start.getFullYear() !== end.getFullYear()
      );
    };
    setIsLongDateRange(checkLongDateRange());
  }, [dateRange]);

  useEffect(() => {
    if (dateRange && !isLongDateRange) {
      const { startDate, endDate } = dateRange;

      fetchSalesRequest({
        startDate,
        endDate,
        brandIDs: selectedBrands?.map(item => item.value),
        brandTitles: selectedBrands?.map(item => item.label),
        orderTypes: selectedOrderTypes?.map(item => item.value),
        products: selectedProducts?.map(item => item.value),
        product_labels: selectedProducts?.map(item => item.label),
      });

      fetchOrders({
        page: currentPage,
        date_range_start: startDate,
        date_range_end: endDate,
        brand_ids: selectedBrands?.map(item => item.value),
        brand_titles: selectedBrands?.map(item => item.label),
        brand_emails: selectedBrands?.map(item => item.contact_email),
        order_types: selectedOrderTypes?.map(item => item.value),
        product_ids: selectedProducts?.map(item => item.value),
        product_labels: selectedProducts?.map(item => item.label),
      });
    }
  }, [
    dateRange,
    currentPage,
    selectedBrands,
    selectedOrderTypes,
    selectedProducts,
    isLongDateRange,
  ]);

  // Functions
  const setFilters = filters => {
    setSelectedBrands(filters.selectedBrands);
    setSelectedOrderTypes(filters.selectedOrderTypes);
    setSelectedProducts([].concat(filters.selectedProducts || []));

    setCurrentPage(0);
  };

  const onExport = useCallback(
    ({ email }) => {
      if (dateRange) {
        const { startDate, endDate } = dateRange;
        fetchSalesXlsxRequest({
          email,
          run_in_background: true,
          date_range_start: startDate,
          date_range_end: endDate,
          brand_ids: selectedBrands?.map(item => item.value),
          order_types: selectedOrderTypes?.map(item => item.value),
          product_ids: selectedProducts?.map(item => item.value),
        });
      }
    },
    [
      fetchSalesXlsxRequest,
      dateRange,
      selectedBrands,
      selectedOrderTypes,
      selectedProducts,
    ]
  );

  return (
    <>
      <ReportsLayout
        title={title}
        loading={pending || brandsPending}
        onDateChange={setDateRange}
        onExport={() => setIsOpenEmailModal(true)}
        date={dateRange}
      >
        {isOpenEmailModal && (
          <EmailPopup
            brands={selectedBrands}
            onSubmit={onExport}
            onClose={() => setIsOpenEmailModal(false)}
          />
        )}
        {isOpenSuccessModal && (
          <PopupBase onClose={() => setIsOpenSuccessModal(false)}>
            <p className={styles.text}>
              Report is being generated. An email will be sent when completed.
            </p>
            <p className={styles.text}>You can continue working!</p>
            <Button
              color="primary"
              className="mx-auto"
              onClick={() => setIsOpenSuccessModal(false)}
            >
              OK
            </Button>
          </PopupBase>
        )}

        {isLongDateRange ? (
          <Alert color="warning">
            <i className="mdi mdi-information" />{' '}
            <b>
              For date ranges over 30 days request a sales report using the
              Export to Excel button.
            </b>
          </Alert>
        ) : (
          <>
            <ReportFilters
              showBrand
              showOrderType
              showSKU
              isMultiBrand
              isMultiWarehouse
              isMultiOrderType
              setFilters={setFilters}
            />

            <Row>
              <Col>
                <SalesStatCard
                  label="Number of Sales"
                  number={salesCount}
                  iconClassName="ri-stack-line"
                />
              </Col>
              <Col>
                <SalesStatCard
                  label="Sales"
                  number={`$ ${formatPrice(salesAll || 0)}`}
                  iconClassName="ri-funds-line"
                />
              </Col>
              <Col>
                <SalesStatCard
                  label="Sales Revenue"
                  subLabel="(without tips and shipping)"
                  number={`$ ${formatPrice(salesRevenue || 0)}`}
                  iconClassName="ri-funds-line"
                />
              </Col>
              <Col>
                <SalesStatCard
                  label="Average Price"
                  number={`$ ${formatPrice(averagePrice || 0)}`}
                  iconClassName="ri-briefcase-line"
                />
              </Col>
            </Row>
            <Row>
              <Col className="mt-4" md={3}>
                <SalesStatCard
                  label="Foot Traffic"
                  number={footTraffic || 0}
                  iconClassName="ri-footprint-line"
                />
              </Col>
              {!!tastingRoomTotal && (
                <Col className="mt-4" md={6}>
                  <SalesStatCard
                    label="Tasting Room Total"
                    number={`$ ${formatPrice(tastingRoomTotal)}`}
                    iconClassName="ri-goblet-fill"
                  />
                </Col>
              )}

              {!!subscriptionsTotal && (
                <Col className="mt-4" md={6}>
                  <SalesStatCard
                    label="Subscriptions Total"
                    number={`$ ${formatPrice(subscriptionsTotal)}`}
                    iconClassName="ri-user-heart-fill"
                  />
                </Col>
              )}

              {!!commercialTotal && (
                <Col className="mt-4" md={6}>
                  <SalesStatCard
                    label="Commercial Total"
                    number={`$ ${formatPrice(commercialTotal)}`}
                    iconClassName="ri-building-line"
                  />
                </Col>
              )}

              {!!containerWorldTotal && (
                <Col className="mt-4" md={6}>
                  <SalesStatCard
                    label="ContainerWorld Total"
                    number={`$ ${formatPrice(containerWorldTotal)}`}
                    iconClassName="ri-money-dollar-circle-line"
                  />
                </Col>
              )}

              {!!ecommerceTotal && (
                <Col className="mt-4" md={6}>
                  <SalesStatCard
                    label="Ecommerce Total"
                    number={`$ ${formatPrice(ecommerceTotal)}`}
                    iconClassName="ri-money-dollar-circle-line"
                  />
                </Col>
              )}

              {!!retailTotal && (
                <Col className="mt-4" md={6}>
                  <SalesStatCard
                    label="Dashboard Total"
                    number={`$ ${formatPrice(retailTotal)}`}
                    iconClassName="ri-shopping-cart-line"
                  />
                </Col>
              )}

              {!!refundsTotal && (
                <Col className="mt-4" md={6}>
                  <SalesStatCard
                    label="Refunds Total"
                    number={`$ ${formatPrice(refundsTotal)}`}
                    iconClassName="ri-refund-line"
                  />
                </Col>
              )}
            </Row>

            <Row className="mt-4">
              <Col md={6}>
                <SalesTipsCard />
              </Col>
              <Col md={6}>
                <SalesOverviewCard />
              </Col>
              <Col md={12}>
                <SalesSourcesCard />
              </Col>
            </Row>
            <Row>
              <Col>
                <Orders
                  setCurrentPage={setCurrentPage}
                  pagination={pagination}
                  orders={orders}
                />
              </Col>
            </Row>
          </>
        )}
      </ReportsLayout>
    </>
  );
};

ReportsSalesSummary.propTypes = {
  title: PropTypes.string,
};

export default ReportsSalesSummary;
